import * as React from "react"
// import Seo from "../components/seo";
import Layout from "../../components/layout"
import Logo from "../../components/logo"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

// markup

const Policies = ({ data }) => {
  return (
    <>
      <Layout>
      <div className="w-9/12 lg:w-3/12 mx-auto mb-9">
       <Logo />
       </div>
        <div className="w-9/12 lg:w-7/12 mx-auto text-left">
          <MDXRenderer>{data.allMdx.nodes[0].body}</MDXRenderer>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allMdx(filter: { slug: { eq: "policies-en" } }) {
      nodes {
        body
      }
    }
  }
`

export default Policies
