import React from "react"
import { StaticImage } from "gatsby-plugin-image"

function Logo() {

  return (
    <div className="logo w-2/3 lg:w-9/12 mx-auto">
      <StaticImage src="../../static/images/logo.png" placeholder="white" alt="logo" />
    </div>
  )
}

export default Logo
